import { ElementType, ReactElement } from 'react';
import { StoryElement } from '@hubcms/domain-story-elements';

export type ImageClassName = 'contentWidthBleed' | 'fullWidthSm' | 'fullWidth' | 'none';
export type ElementRendererFn<T = unknown> = (
  child: ReactElement,
  props: {
    isFirstOfType: boolean;
    elementType: T;
    index: number;
  },
) => ReactElement;

export type ElementRendererProps<T = unknown> = {
  elements: StoryElement[];
  imageClassName?: ImageClassName;
  children?: ElementRendererFn<T>;
};

type ElementRendererConstant = {
  renderer: ElementType<ElementRendererProps>;
};

export const ElementsRenderer: ElementRendererConstant = {
  renderer: () => null,
};
