import { EnrichedAnnotation } from '@hubcms/domain-story-elements';

/**
 * This function is to prevent invalid HTML like <u>This text is underlined and the <b>bold part</u> extends beyond</b>
 * Instead this would result in <u>This text is underlined and the <b>bold part</b></u><b> extends beyond</b>
 */
export function splitOverlappingAnnotations(annotations: EnrichedAnnotation[]) {
  return annotations
    .map((annotation, _, arr) => {
      const overlappingAnnotation = arr.find(
        candidate => candidate.start <= annotation.start && candidate.end > annotation.start && candidate.end < annotation.end,
      );
      if (overlappingAnnotation) {
        return [
          {
            ...annotation,
            end: overlappingAnnotation.end,
          },
          {
            ...annotation,
            start: overlappingAnnotation.end,
          },
        ];
      }
      return annotation;
    })
    .flat();
}
