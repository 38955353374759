import cx from 'classnames';

import { StoryHeaderContext } from '../../../data-access';

import { StoryHeaderHeadline, StoryHeaderIntro, StoryHeaderLabels } from './StoryHeaderElements';
import styles from './story-header.module.scss';
import { StoryHeaderProps } from './types';

function StoryHeader({ as = 'hgroup', className, children, ...contextValue }: StoryHeaderProps) {
  const Component = as;
  return (
    <StoryHeaderContext.Provider value={contextValue}>
      <Component className={cx(className, styles.storyHeader)}>{children}</Component>
    </StoryHeaderContext.Provider>
  );
}
StoryHeader.Headline = StoryHeaderHeadline;
StoryHeader.Intro = StoryHeaderIntro;
StoryHeader.Labels = StoryHeaderLabels;

export default StoryHeader;
