import { CSSProperties, useCallback, useId, useState } from 'react';

import { AdElement } from '@hubcms/ui-ads';
import { useKaChingAd, createAdKey } from '@hubcms/data-access-ads';
import { SlotOptions } from '@hubcms/domain-ads';

export type AdProps = {
  adSlot: string;
  adFormat: string;
  adSlotPrefix?: string;
  isSticky?: boolean;
  adStickyTop?: CSSProperties['top'];
  options?: SlotOptions;
  className?: string;
  style?: CSSProperties;
  onChangeHeight?: (height: number) => void;
};

export const Ad = ({
  adSlot,
  adSlotPrefix,
  adFormat,
  isSticky = false,
  adStickyTop,
  options,
  className,
  style,
  onChangeHeight,
}: AdProps) => {
  const componentId = useId();
  const [isPlaceholder, setIsPlaceholder] = useState(true);
  const renderInitCallback = useCallback(
    ({ size }: { size: number[] }) => {
      setIsPlaceholder(false);
      if (onChangeHeight && size && size.length > 0) {
        onChangeHeight(size[1]);
      }
    },
    [onChangeHeight],
  );
  const { calculatedAdSlot, isPreview, kachingInitData } = useKaChingAd(
    adFormat,
    adSlot,
    adSlotPrefix,
    componentId,
    renderInitCallback,
    options,
  );
  const id = createAdKey(adFormat, calculatedAdSlot);

  if (!calculatedAdSlot || kachingInitData.blockads) {
    return null;
  }

  return (
    <AdElement
      id={id}
      componentId={componentId}
      adFormat={adFormat}
      isPreview={isPreview}
      isSticky={isSticky}
      adStickyTop={adStickyTop}
      className={className}
      hasNoReservedHeight={!isPlaceholder}
      style={style}
    />
  );
};
