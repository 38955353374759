import { EnrichedAnnotation } from '@hubcms/domain-story-elements';

export function sortEnrichedAnnotations(left: EnrichedAnnotation, right: EnrichedAnnotation): number {
  if (left.start < right.start) {
    return -1;
  }
  if (left.start > right.start) {
    return 1;
  }
  if (left.end > right.end) {
    return -1;
  }
  if (left.end < right.end) {
    return 1;
  }
  // subscript and superscript must be applied before strikethrough, otherwise the strike-line is below the text
  if ((left.name === 'subscript' || left.name === 'superscript') && right.name === 'strike') {
    return -1;
  }
  if (left.name === 'strike' && (right.name === 'subscript' || right.name === 'superscript')) {
    return 1;
  }
  return 0;
}
