import { ImageFormat, ImageOrientation } from '@hubcms/domain-images';
import { StoryElement } from './story-element';

export type ImageSource = {
  url: string;
  originalWidth: number;
  originalHeight: number;
};

export type ImageData = ImageSource & {
  alt: string;
  thumbUrl: string;
  orientation: ImageOrientation;
  caption?: string;
  credit?: string;
  alignment?: string;
  alternatives?: Partial<Record<ImageFormat, ImageSource>>;
  backgroundPosition?: `${number}% ${number}%` | null;
};

export type ImageStoryElement = StoryElement<'image', ImageData>;

export function isImageElement(storyElement?: StoryElement | null): storyElement is ImageStoryElement {
  return !!storyElement && (storyElement.type === 'image' || storyElement.type === 'infoblock_image');
}

export function createImageData(partialImageData: Partial<ImageData> = {}): ImageData {
  return {
    url: '',
    alt: '',
    thumbUrl: '',
    originalWidth: 0,
    originalHeight: 0,
    orientation: 'SIXTEEN_NINE',
    ...partialImageData,
  };
}
