import { Heading } from '@mediahuis/chameleon-react';
import { ComponentPropsWithoutRef, MutableRefObject, useContext, useRef } from 'react';

import { StoryHeaderContext } from '../../../../data-access';

import { usePassThrough } from '../../../../hooks/usePassThrough';
import { StoryHeadline } from '../../StoryHeadline';

type StoryHeaderHeadingProps = Omit<ComponentPropsWithoutRef<typeof Heading>, 'children' | 'level'>;

function StoryHeaderHeading({ className, ...headingProps }: StoryHeaderHeadingProps) {
  const { articleId, headingData, isPremium } = useContext(StoryHeaderContext);
  const headlineRef = useRef<HTMLElement>() as MutableRefObject<HTMLElement>;
  usePassThrough({ articleId, headlineRef });

  if (!headingData?.text) {
    return null;
  }

  return (
    <StoryHeadline
      className={className}
      articleId={articleId}
      headingText={headingData.text}
      isPremium={isPremium ?? false}
      {...headingProps}
    />
  );
}

export default StoryHeaderHeading;
