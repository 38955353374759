import { useContext } from 'react';

import { canDisplayLocationInIntro } from '@hubcms/brand';

import { StoryHeaderContext } from '../../../../data-access';

import { StoryIntro } from '../../StoryIntro';

type StoryHeaderIntroProps = {
  hasDropcap?: boolean;
  noPaddingInline?: boolean;
};

function StoryHeaderIntro({ hasDropcap, noPaddingInline }: StoryHeaderIntroProps) {
  const { introData, locationData } = useContext(StoryHeaderContext);

  return (
    <StoryIntro
      hasDropcap={hasDropcap}
      hasLocation={canDisplayLocationInIntro}
      introData={introData}
      locationData={locationData}
      noPaddingInline={noPaddingInline}
    />
  );
}

export default StoryHeaderIntro;
