import { ElementType } from 'react';
import cx from 'classnames';

import styles from './ad-element.module.scss';
import { AdElementProps } from './types';

/**
 * Only use this in @hubcms/ads
 * @param param0
 * @returns
 */
const AdElement = ({
  id,
  adFormat,
  isSticky,
  adStickyTop = 0,
  isPreview = true,
  className,
  hasNoReservedHeight,
  style,
  componentId,
}: AdElementProps<ElementType>) => {
  const adStyles = {
    ...style,
    '--x-ad-sticky-top': adStickyTop,
  };
  return (
    <div
      id={id}
      data-component-id={componentId}
      className={cx(
        styles['ad'],
        styles[`ad--${adFormat}`],
        {
          [styles['adPreview']]: isPreview,
          [styles['adNoReservedHeight']]: hasNoReservedHeight && !isPreview,
          [styles['adIsSticky']]: isSticky,
        },
        className,
      )}
      style={adStyles}
    />
  );
};

export default AdElement;
