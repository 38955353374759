import cx from 'classnames';
import { FC } from 'react';

import styles from './story-section.module.scss';
import { StorySectionProps } from './types';

const StorySection: FC<StorySectionProps> = ({ className, style, isAriaHidden, children, ...props }) => {
  return (
    <section
      className={cx(styles.storySection, className)}
      style={style}
      aria-hidden={isAriaHidden ? 'true' : undefined}
      {...props}
    >
      {children}
    </section>
  );
};

export default StorySection;
