import { StoryElement } from '@hubcms/domain-story-elements';

export default function getAdFormatForStoryElement(
  elementId: string,
  adViableElements: Array<{ storyElement: StoryElement }>,
  positions: number[],
  minElemsAfter: number,
  formats: string[],
) {
  const adIndex = adViableElements.findIndex(({ storyElement: { id } }) => id === elementId);

  if (adIndex < 0) {
    // the element isn't viable
    return null;
  }
  const elementsAfterIndex = adViableElements.slice(adIndex).length;
  if (elementsAfterIndex <= minElemsAfter) {
    // not enough elements following the element to show an ad
    return null;
  }

  // [3,7,11,16]
  const positionIndex = positions.indexOf(adIndex);
  if (positionIndex < 0) {
    // there's no position for the ad
    return null;
  }

  // if the number of ad formats is smaller than the number of positions, we loop through it from the start
  // achieved using modulo
  const format = formats[positionIndex % formats.length].trim();
  if (!format) {
    return null;
  }

  return format;
}
