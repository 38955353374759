import { EnrichedAnnotation } from '@hubcms/domain-story-elements';

import { applyAnnotations } from './applyAnnotations';
import { sortEnrichedAnnotations } from './sortEnrichedAnnotations';
import { splitOverlappingAnnotations } from './splitOverlappingAnnotations';

export function annotate(originalText: string, annotations: EnrichedAnnotation[]) {
  if (!annotations || annotations.length === 0) {
    return originalText;
  }

  const sortedAnnotations = splitOverlappingAnnotations(annotations).sort(sortEnrichedAnnotations);
  return applyAnnotations(originalText, sortedAnnotations);
}
