import { useContext, useEffect, useMemo } from 'react';

import { SlotOptions } from '@hubcms/domain-ads';
import { isKachingNewAdKeyFormat } from '@hubcms/brand';

import { KaChingContext } from '../context/KaChing/KaChingContext';
import { createAdKey } from '../context';

export function useKaChingAd(
  adFormat: string,
  adSlot: string,
  adSlotPrefix: string | undefined,
  componentId: string,
  renderInitCallback: ({ isEmpty, size }: { isEmpty: boolean; size: number[] }) => void,
  options?: SlotOptions,
) {
  const { registerAd, unregisterAd, adFormatCounter, isPreview, kachingInitData } = useContext(KaChingContext);

  const calculatedAdSlot = useMemo(() => {
    if (isKachingNewAdKeyFormat) {
      const adCounterKey = adSlotPrefix || '_';
      const idx = adFormatCounter[adCounterKey]?.indexOf(componentId) ?? -1;
      if (idx !== -1) {
        return `${idx + 1}`;
      }
      // on certain pages we don't have an identical DOM between the client and the server (eg articles with a paywall).
      // this is because we inject data in cloudflare.this causes the useId() hook to not generate consistent IDs.
      // this is why we check whether the current ID exists on the page.

      if (typeof document !== 'undefined' && adFormatCounter[adCounterKey]?.length > 0) {
        adFormatCounter[adCounterKey] = adFormatCounter[adCounterKey].filter(elementId => {
          const element = document.querySelector(`[data-component-id="${elementId}"]`);
          return !!element;
        });
      }
      adFormatCounter[adCounterKey] = (adFormatCounter[adCounterKey] ?? []).concat(componentId);
      if (adSlotPrefix) {
        return `${adSlotPrefix}-${adFormatCounter[adCounterKey].length}`;
      }
      return `${adFormatCounter[adCounterKey].length}`;
    }

    if (!/\d/.test(adSlot)) {
      const idx = adFormatCounter[adFormat + adSlot]?.indexOf(componentId) ?? -1;
      if (idx !== -1) {
        return `${adSlot}${idx + 1}`;
      }
      adFormatCounter[adFormat + adSlot] = (adFormatCounter[adFormat + adSlot] ?? []).concat(componentId);
      return `${adSlot}${adFormatCounter[adFormat + adSlot].length}`;
    }
    return adSlot;
  }, [adSlot, adSlotPrefix, adFormat, componentId, adFormatCounter]);

  useEffect(
    function () {
      // @ts-expect-error TS2304
      const handleRenderInit = (event: CustomEvent<KaChingRenderInitEvent>) => {
        if (event.detail?.identifier === createAdKey(adFormat, calculatedAdSlot)) {
          renderInitCallback?.({ isEmpty: event.detail.isEmpty, size: event.detail.size });
        }
      };

      if (calculatedAdSlot) {
        registerAd(adFormat, calculatedAdSlot, options);
        document.addEventListener('KaChing.render.init', handleRenderInit);
      }

      return function () {
        if (calculatedAdSlot) {
          unregisterAd(adFormat, calculatedAdSlot);
          document.removeEventListener('KaChing.render.init', handleRenderInit);
        }
      };
    },
    [registerAd, unregisterAd, adFormat, calculatedAdSlot, options, componentId, renderInitCallback],
  );

  return { calculatedAdSlot, isPreview, kachingInitData };
}
