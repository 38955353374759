import { ComponentPropsWithoutRef, useContext } from 'react';
import { showLabelOnEditorialArticle, showSubLabelOnEditorialArticle } from '@hubcms/brand';

import { Labels } from '@hubcms/ui-taxonomy';

import { StoryHeaderContext } from '../../../../data-access';
import { StoryLabels } from '../../StoryLabels';

type StoryHeaderLabelsProps = Omit<ComponentPropsWithoutRef<typeof Labels>, 'label' | 'sublabel' | 'isPremium'>;

function StoryHeaderLabels({ ...labelProps }: StoryHeaderLabelsProps) {
  const { label, sublabel, isPremium } = useContext(StoryHeaderContext);
  return (
    <StoryLabels
      label={label ?? ''}
      sublabel={sublabel ?? ''}
      isPremium={isPremium ?? false}
      showLabel={showLabelOnEditorialArticle}
      showSublabel={showSubLabelOnEditorialArticle}
      {...labelProps}
    />
  );
}

export default StoryHeaderLabels;
