import { POST_ALIGNMENT_CLASSNAME } from '../domain/floating-constants';
import { PreparedElement } from '../domain/prepared-element';

export function insertExtraElements(
  preparedElement: PreparedElement,
  elementIdx: number,
  allElements: PreparedElement[],
): PreparedElement[] {
  const elements = [preparedElement];

  if (preparedElement.isFloating) {
    const previousElement = allElements[elementIdx - 1];
    elements.push({
      ...previousElement,
      storyElement: {
        ...previousElement.storyElement,
        id: `${previousElement.storyElement.id}-post-float`,
      },
      className: POST_ALIGNMENT_CLASSNAME,
      isAriaHidden: true,
    });
  }

  return elements;
}
