const STORY_ELEMENT_TYPES = [
  'audiovisual',
  'code_block',
  'embed',
  'gallery',
  'headline',
  'hero_media',
  'newsletter_widget',
  'image',
  'infoblock_group',
  'infoblock_headline',
  'infoblock_image',
  'infoblock_list_bulleted',
  'infoblock_list_numbered',
  'infoblock_paragraph',
  'infoblock_sub_head',
  'sports_result',
  'interview',
  'interview_answer',
  'interview_question',
  'intro',
  'list_bulleted',
  'list_numbered',
  'live_blog',
  'location',
  'map',
  'mhbe_video',
  'page_break',
  'paragraph',
  'pull_quote',
  'rating',
  'relation',
  'sports_group',
  'sub_head',
  'summary_group',
  'table',
] as const;

export type StoryElementType = (typeof STORY_ELEMENT_TYPES)[number];

export function isStoryElementType(elementType: string): elementType is StoryElementType {
  return STORY_ELEMENT_TYPES.includes(elementType as StoryElementType);
}
