import { isStoryElementType } from '@hubcms/domain-story-elements';
import { ElementType } from 'react';
import { ELEMENT_COMPONENT_MAP } from '../domain/element-component-map';

export function getComponent(storyElementType: string): ElementType | null {
  if (!isStoryElementTypeWithComponent(storyElementType)) {
    return null;
  }
  return ELEMENT_COMPONENT_MAP[storyElementType] ?? null;
}

export function isStoryElementTypeWithComponent(
  storyElementType: string,
): storyElementType is keyof typeof ELEMENT_COMPONENT_MAP {
  return isStoryElementType(storyElementType) && storyElementType in ELEMENT_COMPONENT_MAP;
}
