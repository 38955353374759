import { NewsletterSubscriptionWidget } from '@news-components/newsletter-subscription-widget';
/* eslint-disable-next-line import/no-unassigned-import */
import '@news-components/newsletter-subscription-widget/style.css';
import { useEnv } from '@hubcms/data-access-env';
import { useAuth } from '@hubcms/data-access-auth';
import { newsletterEnvironmentMap } from '@hubcms/domain-newsletter';

import { NewsletterProps } from './types';

export default function Newsletter({ newsletter, location, showIcon, brand, suggestions }: NewsletterProps) {
  const { MH_ENV } = useEnv();
  const { user, isLoading } = useAuth();

  const newsletterEnvironment = newsletterEnvironmentMap.get(MH_ENV) || null;
  const isUserLoggedIn = !!user?.sub;

  if (!newsletterEnvironment || !brand || !location) {
    return null;
  }

  return (
    <NewsletterSubscriptionWidget
      newsletter={newsletter}
      showIcon={showIcon}
      location={location}
      brand={brand}
      environment={newsletterEnvironment}
      suggestions={suggestions}
      isUserLoggedIn={isLoading ? null : isUserLoggedIn}
    />
  );
}
