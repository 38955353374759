import { Fragment } from 'react';
import { ElementsRenderer } from '@hubcms/data-access-story-elements';
import { Ad } from '@hubcms/feature-ads';

import { ELEMENT_COMPONENT_MAP } from '../../domain/element-component-map';
import { elementsReducer, INITIAL_CONCATENATION } from '../../utils/elementsReducer';
import { isStoryElementTypeWithComponent } from '../../utils/getComponent';
import { insertExtraElements } from '../../utils/insertExtraElements';
import { prepareElement } from '../../utils/prepareElement';
import { splitElement } from '../../utils/splitElement';
import getAdFormatForStoryElement from '../../utils/getAdFormatForStoryElement';
import { isElementViableForAd } from '../../utils/isElementViableForAd';
import type { ElementProps } from './types';

export default function Elements({
  elements,
  children: renderElementFn = child => child,
  imageClassName = 'fullWidthSm',
  hasAds = false,
  adSectionParams,
}: ElementProps<keyof typeof ELEMENT_COMPONENT_MAP>) {
  const preparedElements = elements
    .filter(({ type }) => isStoryElementTypeWithComponent(type))
    .map(prepareElement(imageClassName))
    .flatMap(insertExtraElements)
    .flatMap(preparedElement => splitElement(preparedElement, hasAds));

  const adViableElements = preparedElements.filter(preparedElement =>
    isElementViableForAd(preparedElement, adSectionParams?.adMinTextLength ?? 0),
  );

  const getAdOptions =
    hasAds && !!adSectionParams
      ? (id: string) =>
          getAdFormatForStoryElement(
            id,
            adViableElements,
            adSectionParams.adPositions,
            adSectionParams.adMinElemsAfter,
            adSectionParams.adFormats,
          )
      : () => null;
  const adParams = {
    getAdOptions,
    AdElement: Ad,
  };

  const concatenatedElements = preparedElements.reduce(
    elementsReducer(renderElementFn, adParams),
    INITIAL_CONCATENATION,
  ).elements;

  return (
    <>
      {concatenatedElements.map(({ key, jsx }) => (
        <Fragment key={key}>{jsx}</Fragment>
      ))}
    </>
  );
}

ElementsRenderer.renderer = Elements;
